import { SidebarProvider } from '@repo/ui/components/Sidebar.js'
import type { PropsWithChildren } from 'react'
import { Sidebar } from './Sidebar'
import { TOPBAR_HEIGHT, Topbar } from './Topbar'

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <SidebarProvider>
      <Topbar />
      <Sidebar />
      <main
        className="relative flex h-full flex-1 flex-col overflow-y-auto px-8 py-4 pb-12"
        style={{
          marginTop: TOPBAR_HEIGHT,
          height: `calc(100svh - ${TOPBAR_HEIGHT})`
        }}
      >
        {children}
      </main>
    </SidebarProvider>
  )
}
