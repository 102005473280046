import { Link } from '@remix-run/react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '@repo/ui/components/DropdownMenu.js'
import { Icon } from '@repo/ui/components/Icon.js'
import { Spinner } from '@repo/ui/components/Spinner.js'
import { cn } from '@repo/ui/utils/utils'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { useExtensionStatus } from '~/hooks/useExtensionStatus'

export const ExtensionStatus = () => {
  const { CONSTANTS } = useClientEnvironmentVariables()

  const { state, isActive, checkStatus } = useExtensionStatus()

  return (
    <div
      className={cn(
        'font-normal text-xs cursor-default px-3 rounded-sm select-none flex items-center gap-2 border',
        state === 'loading'
          ? 'bg-secondary'
          : isActive
            ? 'bg-success-50 border-success-200 text-success-900'
            : 'bg-warning-50 border-warning-200 text-warning-900'
      )}
    >
      <div className="size-3 flex items-center justify-center">
        {state === 'loading' ? (
          <Spinner size="xs" />
        ) : (
          <span
            className={cn(
              isActive ? 'bg-success-400' : 'bg-warning-500',
              'rounded-full size-2 animate-pulse'
            )}
          />
        )}
      </div>

      <span>
        {state === 'loading'
          ? 'Checking extension'
          : isActive
            ? ' Extension active'
            : ' Extension inactive'}
      </span>
      {!isActive && state === 'idle' ? (
        <button
          type="button"
          onClick={checkStatus}
          className="p-1 bg-warning-100 border border-warning-200 rounded-sm hover:bg-warning-200 cursor-pointer"
        >
          <Icon name="rotate-cw" size="sm" />
        </button>
      ) : null}

      <DropdownMenu>
        <DropdownMenuTrigger>
          <Icon name="dots-vertical" size="sm" />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <Link to={CONSTANTS.CHROME_EXTENSION_URL} target="_blank">
            <DropdownMenuItem className="text-xs" icon="external-link">
              Chrome Web Store
            </DropdownMenuItem>
          </Link>
          {/* <Link to={CONSTANTS.EDGE_EXTENSION_URL} target="_blank">
            <DropdownMenuItem className="text-xs" icon="external-link">
              Microsoft Edge Add-on
            </DropdownMenuItem>
          </Link> */}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
