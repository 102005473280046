import Intercom from '@intercom/messenger-js-sdk'
import { useEffect } from 'react'

import { useAuthenticatedOrg } from '~/hooks/useAuthenticatedOrg'
import { useAuthenticatedUser } from '~/hooks/useAuthenticatedUser'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'

export const useIntercom = () => {
  const { INTERCOM_APP_ID, INTERCOM_HASH } = useClientEnvironmentVariables()

  const user = useAuthenticatedUser()
  const org = useAuthenticatedOrg()

  useEffect(() => {
    if (typeof document === 'undefined') return

    const createdAt = new Date(user.createdAt)
    const unixTimestamp = Math.floor(createdAt.getTime() / 1000)

    Intercom({
      api_base: 'https://api-iam.intercom.io',
      app_id: INTERCOM_APP_ID,
      name: user.fullName,
      user_id: user.id,
      email: user.email,
      created_at: unixTimestamp,
      user_hash: INTERCOM_HASH,
      avatar: {
        type: 'avatar',
        image_url: user.avatarUrl
      },
      company: {
        company_id: org.id,
        name: org.name,
        created_at: org.createdAt
      }
    })
  }, [user, org, INTERCOM_HASH, INTERCOM_APP_ID])
}
